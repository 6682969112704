'use client';

import { Button, EmptyView } from '@/components';
import { Element, Icon } from '@vuddy/components';

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <Element paddingX="padding_xl">
      <EmptyView
        title="오류가 발생했습니다."
        description="잠시 후 다시 시도해 주세요.">
        <Button variant="outlineSecondary" size="small" onClick={() => reset()}>
          <Icon name="OUTLINE_RELOAD_24" size="16px" />
          새로고침
        </Button>
      </EmptyView>
    </Element>
  );
}
